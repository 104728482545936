<template>
  <span class="layout-upload pointer" @click="uploading||disabled?'':$refs.file.click()">
    <slot></slot>
    <div v-if="uploading" class="text-upload">
      <span>Uploading...</span>
    </div>
    <input v-show="false" ref="file" type="file" accept="image/jpeg" @change="onFileChange">
  </span>
</template>

<script>
import ImgDefault from '@/assets/images/image.png';
// var AWS = require("aws-sdk"); 
// var uuid = require('uuid');
// import aws from 'aws-sdk'
import S3 from 'aws-s3';

export default {
  props: {
    src: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    directory: {
      required: true,
      type: String,
      // default: 'thumbnails'
      /*
      ชื่อ folder เก็บรูป logo ร้านค้า  : logo/
      ชื่อ folder สำเนาบัตรประชาชน  : identification_card/
      ชื่อ folder เก็บไฟล์ลายเซ็น   : e_signature/
      ชื่อ folder เก็บสำเนารถ    : car_registration_book/
      ชื่อ folder QR code สแกนจ่าย    : qr_code_pay/
      */
    },
  },
  data () {
    return {
      file: null,
      defaultImage: ImgDefault,
      uploading: false,

      aws_access_key_id: 'AKIA2ETG3H3HEVIJOFU3',
      aws_secret_access_key: '---',
      aws_default_region    : 'ap-southeast-1',
      aws_bucket     : 'permtang',
      aws_url        : 'https://permtang.s3-ap-southeast-1.amazonaws.com',
    }
  },
  methods: {
    upload(file) {
      const config = {
        bucketName: this.aws_bucket,
        dirName: this.directory,
        region: this.aws_default_region,
        accessKeyId: this.aws_access_key_id,
        secretAccessKey: this.aws_secret_access_key,
        s3Url: this.aws_url
      };
      console.log(config)
      const S3Client = new S3(config);
      const newFileName = Math.random().toString().slice(2);
      
      return new Promise( (fulfill, reject) => {
        S3Client.uploadFile(file, newFileName)
        .then(data => fulfill(data) )
        .catch(err => reject(err))
      });
    },
    onFileChange(e) {
        const file  = e.target.files[0];
        const mb = 524288; // 524 kb
        if(!file)
            return; 
        if(file.size > mb*this.limit) {
            alert('Allowed file size exceeded. (Max. '+(mb*this.limit)+' MB)');
            return; 
        }
        this.file = file;
        this.uploading = true;

        this.upload(file).then((res) => {
          this.uploading = false;
          this.$emit('onChangeUrl', res.location);
        })
    }
  },
  computed: {
    imagePreview() {
      if(this.file) {
        return URL.createObjectURL(this.file);
      }
      else
        return this.src;
    }
  },
}
</script>

<style lang="scss" scoped>
.layout-upload {
  position: relative;
  
  .text-upload {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;

    span {
      padding: 2px 15px;
      color: #ffffff;
      background-color: rgba(#000000,0.5);
      border-radius: 3px;
      font-size: 18px;
    }
  }
}
</style>